import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import tw from 'twin.macro';

const GridStyled = styled.div`
  ${tw`grid grid-cols-3 gap-3`}
`;

const GridItemStyled = styled.div`
  ${tw`shadow-md rounded-md p-3 cursor-pointer`}
`;

const CategoryImageStyled = styled(GatsbyImage)`
  ${tw`mx-auto block mb-2 w-16 max-w-full`}
`;

const CategoryTitleStyled = styled.h4`
  ${tw`text-center text-gray-600`}
  font-size: 10px;
`;

export { GridStyled, GridItemStyled, CategoryImageStyled, CategoryTitleStyled };
