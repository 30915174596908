import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { uniqueId } from 'lodash';

import categories from '../../../../constants/categories.json';
import pages from '../../../../constants/pages.json';
import { navigateToCustomPage } from '../../../../utils/navigate';

import { GridStyled, GridItemStyled, CategoryImageStyled, CategoryTitleStyled } from './styles';
import { LangContext } from '../../../../context/lang.context';
interface CategoryProps {
  get: (key: string) => string;
  token: string;
  params: any;
}

const Category = ({ token, params }: CategoryProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { extension: { regex: "/(png)/" }, relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      }
      allI18NJson {
        nodes {
          locale
          clientId
          SERVICES {
            ...categoriesText
          }
        }
      }
    }
  `);

  const findImage = (key: string) =>
    data.allImages?.edges.find(({ node: { base } }: any) => base === key)?.node.childImageSharp;
  const handleClick = (category: any) => {
    navigateToCustomPage(pages.SEARCH_CATEGORY, { state: { category, token } })?.();
  };

  const type = params.type === 'details' ? params.category : params.type;
  const namedCategory = categories.find(category => category.name?.toLowerCase() === type?.toLowerCase());

  if (namedCategory) {
    navigateToCustomPage(pages.SEARCH_CATEGORY, { state: { category: namedCategory, company: params.company, token } })?.();
    return false;
  }
  return (
    <GridStyled>
      {categories.map(({ name, image }) => (
        <GridItemStyled key={uniqueId()} onClick={() => handleClick({ name, image })}>
          <CategoryImageStyled loading="eager" image={findImage(image).gatsbyImageData} alt={'Category image'} />
          <CategoryTitleStyled>{t(data)?.SERVICES.CATEGORIES[name]}</CategoryTitleStyled>
        </GridItemStyled>
      ))}
    </GridStyled>
  );
};

export { Category };
