import React, { useEffect, memo, useContext } from 'react';
import pages from '../../constants/pages.json';
import categories from '../../constants/categories.json';
import { SearchForm } from '../../components/forms/search-services';
import { navigateToCustomPage } from '../../utils/navigate';
import { Title, PStyled } from '../../styles/pages/search';
import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { LangContext } from '../../context/lang.context';
import { graphql, useStaticQuery } from 'gatsby';

const RenderLayout = memo(

  ({ token, params }: any) => {
const { t } = useContext(LangContext);
const data = useStaticQuery(graphql`
query {
  allI18NJson {
    nodes {
      locale
      clientId
      SERVICES {
        TITLE
        DESCRIPTION_1
        DESCRIPTION_2
        DESCRIPTION_3
        DESCRIPTION_4
      }
    }
  }
}
`);
    return (
      <Layout>
        <Topbar />
        <section className="content pb-10">
          <Title>{t(data).SERVICES.TITLE}</Title>
          <PStyled className="mb-6">
            {t(data).SERVICES.DESCRIPTION_1} <b>{t(data).SERVICES.DESCRIPTION_2}</b> {t(data).SERVICES.DESCRIPTION_3}{' '}
            <b>{t(data).SERVICES.DESCRIPTION_4}</b>
          </PStyled>

          <SearchForm  token={token} params={params} />
        </section>
      </Layout>
    );
  },
  ({ render: prev }, { render: next }) => prev === next
);

const SearchPage = ({ getToken, params }: PageProps) => {
  const token: string = getToken();
  useEffect(() => {
    if (params.type === 'donation') {
      const donation = categories.find(category => category.name === 'Donaciones');
      navigateToCustomPage(pages.SEARCH_CATEGORY, { state: { category: donation, token } })?.();
    }
  });

  return <RenderLayout token={token} params={params} />;
};

export default SearchPage;
