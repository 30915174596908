import React, { useState, useEffect, useContext } from 'react';

import { FormProps, SearchProps } from '../models';

import { Radio } from '../../inputs/radio/radio';

import { Category } from './category';
import { Company } from './company';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import { CreditcardContext } from '../../../context/creditcard.context';

const RenderSearch = ({ get, type, token, params }: SearchProps) => {
  return (({
    category: <Category get={get} token={token} params={params} />,
    company: <Company get={get} token={token} />,
  } as any)[type ?? 'company'])};

const SearchForm = ({ get, token, params }: FormProps) => {
  const isCategory = params.type !== 'details' || (params.type === 'details' && params.category);
  const [searchType, setSearchType] = useState<'company' | 'category'>(isCategory ? 'category' : 'company');

  const { t } = useContext(LangContext);
  const { cleanSearchResults } = useContext(CreditcardContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SERVICES {
            BUTTON {
              ENTRY
              COMPANY
            }
          }
        }
      }
    }
  `);

  /**
   * @hack
   * This is an ugly (but smart) hack caused by some hydration issues when doing SSR;
   * here's a very extensive discussion about the issue:
   * https://github.com/gatsbyjs/gatsby/issues/17914
   *
   * The hack is inspired on the second proposed Workaround, "Two pass rendering",
   * from the first comment:
   * https://github.com/gatsbyjs/gatsby/issues/17914#issue-498943894
   *
   * We define a simple local state, which will change only once at the time of mount,
   * and we use the state on a `key` prop for the container element of the problematic components
   * (in this case, the buttons and categories list).
   */
  const [platform, setPlatform] = useState('ssr');
  useEffect(() => {
    setPlatform('browser');
  }, []);

  return (
    <div key={platform}>
      <div className="grid gap-6 grid-cols-2 mb-5">
        <Radio
          title={t(data).SERVICES.BUTTON.ENTRY}
          group="search-type"
          value="category"
          padding="4"
          checked={searchType === 'category'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            cleanSearchResults();
            return setSearchType(e.target.value as 'category');
          }}
        />

        <Radio
          title={t(data).SERVICES.BUTTON.COMPANY}
          group="search-type"
          value="company"
          padding="4"
          checked={searchType === 'company'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchType(e.target.value as 'company')}
        />
      </div>

      <RenderSearch type={searchType} get={get} token={token} params={params} />
    </div>
  );
};

export { SearchForm };
